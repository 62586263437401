import React, {useState, useEffect} from 'react';
import { StyleSheet, View, Pressable, Image } from 'react-native';

import tw from '../../styles/twrnc-config';
import Text from '../../components/Text';

/* Image Picker elemento */
import * as ImagePicker from 'expo-image-picker';

import { uploadFile } from '../../services/General';
import { useSelector, useDispatch } from "react-redux";
import { subeImagenFrenteINE, subeImagenReversoINE, subeImagenPasaporte, subeImagenFrenteLic, subeImagenReversoLic } from '../../store/slices/imagenes'
import { setDocumentoURL, deleteReversoPasaporte } from '../../store/slices/documentos';

import IneIcon from '../../assets/icons/ineFrente';
import IneReversoIcon from '../../assets/icons/ineReverso';
import CheckFirmas from '../../assets/icons/check';
import PasaporteIcon from '../../assets/icons/pasaporte';
import LicenciaFrente from '../../assets/icons/licenciaFrente';
import LicenciaReverso from '../../assets/icons/licenciaReverso';

import {ModalCarga} from './ModalCarga';
import ModalOtrosDoc from './ModalOtrosDoc';
import FooterAltaDocumentos from './FooterAltaDocumentos';


const styles = StyleSheet.create({
    imageSuccess: {
        top:-29,
        left:280
    }
});

export default function FormaAltaDocumentos({navigation}) {
    const dispatch = useDispatch();
    const Contrato = useSelector(state => state.contratos);
    const ImagenesData = useSelector(state => state.imagenes);
    const id_activacion = Contrato.contratos.codigoActivacion;
    const id_empresa = Contrato.contratos.idEmpresa;
    const [visible, setVisible] = useState(false);
    const [imagenFrenteINE, setImagenFrenteINE] = useState(false);
    const [imagenReversoINE, setImagenReversoINE] = useState(false);
    const [imagenPasaporte, setImagenPasaporte] = useState(false);
    const [imagenFrenteLic, setImagenFrenteLic] = useState(false);
    const [imagenReversoLic, setImagenReversoLic] = useState(false);
    const [idActivacion, setIdActivacion] = useState(false);
    const [uriReversoINE, setUriReversoINE] = useState("");
    const [uriFrenteINE, setUriFrenteINE] = useState("");
    const [uriPasaporte, setUriPasaporte] = useState("");
    const [uriReversoLic, setUriReversoLic] = useState("");
    const [uriFrenteLic, setUriFrenteLic] = useState("");
    const [visibleCarga, setVisibleCarga] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [selected, setSelect] = useState("INE");
    const [selectedDoc, setSelectedDoc] = useState("");

    const [visibleModalOtros, setVisibleModalOtros] = useState(false);
    
    const changeINE = () =>{
        setSelect("INE");
    }
    const changePasaporte = () =>{
        setSelect("Pasaporte");
    }

    useEffect(() => {
        if(Contrato.contratos?.imagenesIdentificacion && Contrato.contratos?.imagenesIdentificacion.length  > 0){
            Contrato.contratos.imagenesIdentificacion.map(element => {
                if(element.includes('identificacion_frente')){
                    //setImagenFrenteINE(element);
                    //setImagenFrenteLic(element);
                    //setImagenPasaporte(element);
                    if(element)
                    setUriFrenteINE(element);
                    setUriPasaporte(element);
                    setUriFrenteLic(element);
                }else if(element.includes('identificacion_reverso')){
                    //setImagenReversoINE(element);
                    //setImagenReversoLic(element);
                    setUriReversoINE(element);
                    setUriReversoLic(element);

                }
            });
        }
    });

    useEffect(() => {
        if(Contrato.contratos?.imagenesIdentificacion && Contrato.contratos?.imagenesIdentificacion.length  > 0){
            Contrato.contratos.imagenesIdentificacion.map(element => {
                if(element.includes('identificacion_frente')){
                    if(uriFrenteINE !== "" && uriFrenteINE !== element){
                        setUriFrenteINE(uriFrenteINE);
                    }else{
                        setUriFrenteINE(element);
                    }
                    
                }else if(element.includes('identificacion_reverso')){
                    if(uriReversoINE !== "" && uriReversoINE !== element){
                        setUriReversoINE(uriReversoINE);
                    }else{
                        setUriReversoINE(element);
                    }
                }
            });
        }
    }, [uriFrenteINE, uriReversoINE]);

    useEffect(() => {
        if(Contrato.contratos?.imagenesIdentificacion && Contrato.contratos?.imagenesIdentificacion.length  > 0){
            Contrato.contratos.imagenesIdentificacion.map(element => {
                if(element.includes('identificacion_frente')){
                    if(uriPasaporte !== "" && uriPasaporte !== element){
                        setUriPasaporte(uriPasaporte);
                    }else{
                        setUriPasaporte(element);
                    }
                }
            });
        }
    }, [uriPasaporte]);

    useEffect(() => {
        if(Contrato.contratos?.imagenesIdentificacion && Contrato.contratos?.imagenesIdentificacion.length  > 0){
            Contrato.contratos.imagenesIdentificacion.map(element => {
                if(element.includes('identificacion_frente')){
                    if(uriFrenteLic !== "" && uriFrenteLic !== element){
                        setUriFrenteLic(uriFrenteLic);
                    }else{
                        setUriFrenteLic(element);
                    }
                }else if(element.includes('identificacion_reverso')){
                    if(uriReversoLic !== "" && uriReversoLic !== element){
                        setUriReversoLic(uriReversoLic);
                    }else{
                        setUriReversoLic(element);
                    }
                }
            });
        }
    }, [uriFrenteLic, uriReversoLic]);

    useEffect(() => {
        if (selectedDoc === "Licencia") {
            setSelect("Licencia");
        }
    }, [selectedDoc]);

    useEffect(() => {
        if (ImagenesData !== undefined) {
            setImagenFrenteINE(ImagenesData.imagenFrenteINE);
            setImagenReversoINE(ImagenesData.imagenReversoINE);
            setImagenPasaporte(ImagenesData.imagenPasaporte);
            setImagenFrenteLic(ImagenesData.imagenFrenteLic);
            setImagenReversoLic(ImagenesData.imagenReversoLic);
            setIdActivacion(id_activacion);
        }
    }, [ImagenesData]);

    const tomarFrenteINE = async () => {
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (!permissionResult.granted) {
            alert('Los permisos para acceder a tu cámara son necesarios');
            return;
        }

        const FrenteINE = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 1,
            base64: false,
        });

        if (!FrenteINE.canceled) {
            const uri = FrenteINE.assets[0];
            setUriFrenteINE(uri);
            dispatch(setDocumentoURL({"newDocumento":{"tipo_documento":"identificacion_frente","uri":uri}}));
        } else {
            console.log("El usuario canceló la toma de foto");
            return;
        }
    };

    const tomarReversoINE = async () => {
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (!permissionResult.granted) {
            alert('Los permisos para acceder a tu cámara son necesarios');
            return;
        }

        const ReversoINE = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 1,
            base64: false,
        });

        if (!ReversoINE.canceled) {
            const uriRev = ReversoINE.assets[0];
            setUriReversoINE(uriRev);
            dispatch(setDocumentoURL({"newDocumento":{"tipo_documento":"identificacion_reverso","uri":uriRev}}));
        } else {
            console.log("El usuario canceló la toma de foto");
            return;
        }
    };

    const subirINE = async () =>{
        // openModalCarga();
        /*setIsLoading(true);


            try {
                const respuestaUploadFrenteINE = await uploadFile(uriFrenteINE, id_empresa, idActivacion, 'identificacion_frente');

                if (respuestaUploadFrenteINE.Success) {
                    dispatch(setDocumentoURL({"newDocumento":respuestaUploadFrenteINE.data.Location}));
                    //dispatch(subeImagenFrenteINE(respuestaUploadFrenteINE.Success, respuestaUploadFrenteINE.data.Location));
                    setImagenFrenteINE(respuestaUploadFrenteINE.Success);
                    // closeModalCarga();
                    setIsLoading(false);
                } else {
                    alert(`Error al subir el archivo : ${respuestaUploadFrenteINE.Error}`);
                    // closeModalCarga();
                    return false;
                }
            } catch (e) {
                console.log("ERROR : ", e);
                alert(`Error al subir el archivo`);
                return false;
            }

            setIsLoading(true);
            try {
                const respuestaUploadReversoINE = await uploadFile(uriReversoINE, id_empresa, idActivacion, 'identificacion_reverso');

                if (respuestaUploadReversoINE.Success) {
                    dispatch(setDocumentoURL({"newDocumento":respuestaUploadReversoINE.data.Location}));
                    //dispatch(subeImagenReversoINE(respuestaUploadReversoINE.Success, respuestaUploadReversoINE.data.Location));
                    setImagenReversoINE(respuestaUploadReversoINE.Success);
                    setIsLoading(false);
                    return true;
                } else {
                    alert(`Error al subir el archivo : ${respuestaUploadReversoINE.Error}`);
                    setIsLoading(false);
                    return false;
                }
            } catch {
                alert(`Error al subir el archivo`);
                return false;
            }*/

    }

    const tomarPasaporte = async () => {
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (!permissionResult.granted) {
            alert('Los permisos para acceder a tu cámara son necesarios');
            return;
        }

        const Pasaporte = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 1,
            base64: false,
        });

        if (!Pasaporte.canceled) {
            const uriPas = Pasaporte.assets[0];
            setUriPasaporte(uriPas);
            dispatch(deleteReversoPasaporte());
            dispatch(setDocumentoURL({"newDocumento":{"tipo_documento":"identificacion_frente","uri":uriPas}}));
        } else {
            console.log("El usuario canceló la toma de foto");
            return;
        }
    };

    const subirPasaporte = async () =>{
        /*setIsLoading(true);
            try {
                const respuestaUploadPasaporte = await uploadFile(uriPasaporte, id_empresa, idActivacion, 'identificacion_frente');

                if (respuestaUploadPasaporte.Success) {
                    dispatch(setDocumentoURL({"newDocumento":respuestaUploadPasaporte.data.Location}));
                    //dispatch(subeImagenPasaporte(respuestaUploadPasaporte.Success, respuestaUploadPasaporte.data.Location));
                    setImagenPasaporte(respuestaUploadPasaporte.Success);
                    setIsLoading(false);
                    return true;
                } else {
                    alert(`Error al subir el archivo : ${respuestaUploadPasaporte.Error}`);
                    return; false
                }
            } catch {
                alert(`Error al subir el archivo`);
                return false;
            }*/
    }

    const tomarFrenteLic= async () => {
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (!permissionResult.granted) {
            alert('Los permisos para acceder a tu cámara son necesarios');
            return;
        }

        const FrenteLic = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 1,
            base64: false,
        });

        if (!FrenteLic.canceled) {
            const urifrenteLic = FrenteLic.assets[0];
            setUriFrenteLic(urifrenteLic);
            dispatch(setDocumentoURL({"newDocumento":{"tipo_documento":"identificacion_frente","uri":urifrenteLic}}));
            

        } else {
            console.log("El usuario canceló la toma de foto");
            return;
        }
    };

    const tomarReversoLic = async () => {
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync();
        if (!permissionResult.granted) {
            alert('Los permisos para acceder a tu cámara son necesarios');
            return;
        }

        const ReversoLic = await ImagePicker.launchCameraAsync({
            allowsEditing: true,
            quality: 1,
            base64: false,
        });

        if (!ReversoLic.canceled) {
            const uriRevLic = ReversoLic.assets[0];
            setUriReversoLic(uriRevLic);
            dispatch(setDocumentoURL({"newDocumento":{"tipo_documento":"identificacion_reverso","uri":uriRevLic}}));

        } else {
            console.log("El usuario canceló la toma de foto");
            return;
        }
    };
    const subirLicencia = async () =>{
        /*setIsLoading(true);
            try {
                const respuestaUploadFrenteLic = await uploadFile(uriFrenteLic, id_empresa, idActivacion, 'identificacion_frente');

                if (respuestaUploadFrenteLic.Success) {
                    dispatch(setDocumentoURL({"newDocumento":respuestaUploadFrenteLic.data.Location}));
                    //dispatch(subeImagenFrenteLic(respuestaUploadFrenteLic.Success, respuestaUploadFrenteLic.data.Location));
                    setImagenFrenteLic(respuestaUploadFrenteLic.Success);
                    setIsLoading(false);
                } else {
                    alert(`Error al subir el archivo : ${respuestaUploadFrenteLic.Error}`);
                    setIsLoading(false);
                    return false;
                }
            } catch (e) {
                console.log("ERROR : ", e);
                alert(`Error al subir el archivo`);
                return false;
            }
            setIsLoading(true);
            try {
                const respuestaUploadReversoLic = await uploadFile(uriReversoLic, id_empresa, idActivacion, 'identificacion_reverso');

                if (respuestaUploadReversoLic.Success) {
                    dispatch(setDocumentoURL({"newDocumento":respuestaUploadReversoLic.data.Location}));
                    //dispatch(subeImagenReversoLic(respuestaUploadReversoLic.Success, respuestaUploadReversoLic.data.Location));
                    setImagenReversoLic(respuestaUploadReversoLic.Success);
                    setIsLoading(false);
                    return true;
                } else {
                    alert(`Error al subir el archivo : ${respuestaUploadReversoLic.Error}`);
                    setIsLoading(false);
                    return false;
                }
            } catch {
                alert(`Error al subir el archivo`);
                return false;
            }*/
    }

    const openModalCarga = () => {
        setVisibleCarga(true);
    };

    const closeModalCarga = () => {
        setVisibleCarga(false);
    };

    const openModalOtrosDoc = () =>{
        setVisibleModalOtros(true);
    }
    const closeModalOtrosDoc = () =>{
        setVisibleModalOtros(false);
    }
    console.log(uriFrenteINE);
    console.log(uriReversoINE);
    return (
        <>
            <View style={tw`w-full bg-bgGrey flex-grow mt-4 pl-7 pr-7`}>
                <View style={tw`flex-row  mb-8 justify-between`}>
                {selected == "INE" ? 
                    (<>
                        <Pressable
                            style={tw`p-5 mr-2 flex-1 rounded-xl border border-primary bg-tabs mt-5`}
                            onPress={changeINE}
                        >
                            <Text style={tw`text-center text-xl`}>
                                INE
                            </Text>
                        </Pressable>
                    </>)
                    :
                    (<>
                        <Pressable
                            style={tw`p-5 mr-2 flex-1 rounded-xl border border-primary bg-white mt-5`}
                            onPress={changeINE}
                        >
                            <Text style={tw`text-center text-xl`}>
                                INE
                            </Text>
                        </Pressable>
                    </>)

                }
                {selected == "Pasaporte" ? 
                    (<>
                        <Pressable
                            style={tw`p-5 ml-2 flex-1 rounded-xl border border-primary bg-tabs mt-5`}
                            onPress={changePasaporte}
                        >
                            <Text style={tw`text-center text-xl`}>
                                Pasaporte
                            </Text>
                        </Pressable>
                    </>)
                    :
                    (<>
                        <Pressable
                            style={tw`p-5 mr-2 flex-1 rounded-xl border border-primary bg-white mt-5`}
                            onPress={changePasaporte}
                        >
                            <Text style={tw`text-center text-xl`}>
                                Pasaporte
                            </Text>
                        </Pressable>
                    </>)

                }        
                   
                </View>
                {selected == "INE" ? 
                    (<>
                       <View style={[tw`bg-white pl-4 pr-4 pt-8 pb-8 rounded-3xl shadow-lg mb-8`]}>
                            <View style={[tw`flex-row justify-between`]} >
                                <Text style={[tw`text-xl text-primaryText mb-4 ml-1`]}>Frente</Text>
                                {uriFrenteINE != "" ? 
                                ( <CheckFirmas fill={'#45ba47'} width={22} height={22} style={[tw`mb-5`]}  />):
                                <></>
                                }
                            </View>
                            {
                                uriFrenteINE != "" ?
                                (
                                    <View>
                                        <Pressable style={[tw`rounded-lg items-center`]} onPress={tomarFrenteINE}>
                                            <Image source={{ uri: uriFrenteINE?.uri ? uriFrenteINE?.uri : uriFrenteINE }} style={{ width: 250, height: 150, resizeMode: 'contain'}} />
                                        </Pressable>
                                    </View>
                                )
                                :
                                (
                                    <View>
                                        <Pressable style={[tw`border-2 pl-6 pr-6 pt-8 pb-8 border-gray-300 rounded-lg items-center`]} onPress={tomarFrenteINE}>
                                            <IneIcon width={100} height={70}/>
                                            <Text style={[tw`text-datosText text-lg`]}>Toca para tomar foto</Text>
                                        </Pressable>
                                    </View>
                                )
                            }
                        </View>
                        <View style={[tw`bg-white pl-4 pr-4 pt-8 pb-8 rounded-3xl shadow-lg mb-8`]}>
                            <View style={[tw`flex-row justify-between`]} >
                                <Text style={[tw`text-xl text-primaryText mb-4 ml-1`]}>Reverso</Text>
                                {uriReversoINE != "" ? 
                                ( <CheckFirmas fill={'#45ba47'} width={22} height={22} style={[tw`mb-5`]}  />):
                                <></>
                                }
                            </View>
                                {
                                    uriReversoINE != "" ?
                                    (
                                        <View>
                                            <Pressable style={[tw`rounded-lg items-center`]} onPress={tomarReversoINE}>
                                                <Image source={{ uri: uriReversoINE?.uri ? uriReversoINE?.uri : uriReversoINE }} style={{ width: 250, height: 150, resizeMode: 'contain'}} />
                                            </Pressable>
                                        </View>
                                    )
                                    :
                                    (
                                        <View>
                                        <Pressable style={[tw`border-2 pl-6 pr-6 pt-8 pb-8 border-gray-300 rounded-lg items-center`]} onPress={tomarReversoINE}>
                                            <IneReversoIcon width={100} height={70}/>
                                            <Text style={[tw`text-datosText text-lg`]}>Toca para tomar foto</Text>
                                        </Pressable>
                                    </View>
                                    )
                                }
                        </View>
                    </>)
                    :
                    (selected == "Pasaporte" ? 
                        (
                            <>
                            <View style={[tw`bg-white pl-4 pr-4 pt-8 pb-8 rounded-3xl shadow-lg mb-8`]}>
                                <View style={[tw`flex-row justify-between`]} >
                                    <Text style={[tw`text-xl text-primaryText mb-4 ml-1`]}>Pasaporte</Text>
                                    {uriPasaporte != "" ? 
                                    ( <CheckFirmas fill={'#45ba47'} width={22} height={22} style={[tw`mb-5`]}  />):
                                    <></>
                                    }
                                </View>
                                {
                                    uriPasaporte != "" ?
                                    (
                                        <View>
                                            <Pressable style={[tw`rounded-lg items-center`]} onPress={tomarPasaporte}>
                                                <Image source={{ uri: uriPasaporte?.uri ? uriPasaporte?.uri : uriPasaporte }} style={{ width: 250, height: 150, resizeMode: 'contain'}} />
                                            </Pressable>
                                        </View>
                                    )
                                    :
                                    (
                                        <View>
                                            <Pressable style={[tw`border-2 pl-6 pr-6 pt-8 pb-8  border-gray-300 rounded-lg items-center`]} onPress={tomarPasaporte}>
                                                <PasaporteIcon width={100} height={70}/>
                                                <Text style={[tw`text-datosText text-lg`]}>Toca para tomar foto</Text>
                                            </Pressable>
                                        </View>
                                    )
                                }
                            </View>
                            </>
                        ):(
                            <>
                                <View style={[tw`bg-white pl-4 pr-4 pt-8 pb-8 rounded-3xl shadow-lg mb-8`]}>
                                        <View style={[tw`flex-row justify-between`]} >
                                            <Text style={[tw`text-xl text-primaryText mb-4 ml-1`]}>Frente Licencia</Text>
                                            {uriFrenteLic != "" ? 
                                            ( <CheckFirmas fill={'#45ba47'} width={22} height={22} style={[tw`mb-5`]}  />):
                                            <></>
                                            }
                                        </View>
                                        {
                                            uriFrenteLic != "" ?
                                            (
                                                <View>
                                                    <Pressable style={[tw`rounded-lg items-center`]} onPress={tomarFrenteLic}>
                                                        <Image source={{ uri: uriFrenteLic?.uri ? uriFrenteLic?.uri : uriFrenteLic }} style={{ width: 250, height: 150, resizeMode: 'contain'}} />
                                                    </Pressable>
                                                </View>
                                            )
                                            :
                                            (
                                                <View>
                                                    <Pressable style={[tw`border-2 pl-6 pr-6 pt-8 pb-8 border-gray-300 rounded-lg items-center`]} onPress={tomarFrenteLic}>
                                                        <LicenciaFrente width={100} height={70}/>
                                                        <Text style={[tw`text-datosText text-lg`]}>Toca para tomar foto</Text>
                                                    </Pressable>
                                                </View>
                                            )
                                        }
                                    </View>
                                    <View style={[tw`bg-white pl-4 pr-4 pt-8 pb-8 rounded-3xl shadow-lg mb-8`]}>
                                        <View style={[tw`flex-row justify-between`]} >
                                            <Text style={[tw`text-xl text-primaryText mb-4 ml-1`]}>Reverso</Text>
                                            {uriReversoLic != "" ? 
                                            ( <CheckFirmas fill={'#45ba47'} width={22} height={22} style={[tw`mb-5`]}  />):
                                            <></>
                                            }
                                        </View>
                                            {
                                                uriReversoLic != "" ?
                                                (
                                                    <View>
                                                        <Pressable style={[tw`rounded-lg items-center`]} onPress={tomarReversoLic}>
                                                            <Image source={{ uri: uriReversoLic?.uri ? uriReversoLic?.uri : uriReversoLic }} style={{ width: 250, height: 150, resizeMode: 'contain'}} />
                                                        </Pressable>
                                                    </View>
                                                )
                                                :
                                                (
                                                    <View>
                                                    <Pressable style={[tw`border-2 pl-6 pr-6 pt-8 pb-8 border-gray-300 rounded-lg items-center`]} onPress={tomarReversoLic}>
                                                        <LicenciaReverso width={100} height={70}/>
                                                        <Text style={[tw`text-datosText text-lg`]}>Toca para tomar foto</Text>
                                                    </Pressable>
                                                </View>
                                                )
                                            }
                                    </View>
                            </>
                        ) 
                    )

                }
                {selectedDoc == "Licencia" || (uriReversoLic != "" &&  uriFrenteLic != "") || (uriPasaporte != "" ) || (uriReversoINE != "" && uriFrenteINE != "")?
                    (
                        <View style={tw`items-center mb-4`}>
                            
                        </View> 
                    ):(
                        <View style={tw`items-center mb-4`}>
                            <Pressable style={[tw``]} onPress={openModalOtrosDoc}>
                                <Text style={tw`text-notasText text-lg underline `}>No cuento con estos documentos</Text>
                            </Pressable>
                        </View> 
                    )
                }
                
                <ModalOtrosDoc visibleModalOtros={visibleModalOtros} closeModalOtrosDoc={closeModalOtrosDoc} setSelectedDocParent={setSelectedDoc}/>
                <ModalCarga visibleCarga={visibleCarga} closeModalCarga={closeModalCarga}></ModalCarga>
                
            </View>
            <FooterAltaDocumentos  
                navigation={navigation}
                uriFrenteINE={uriFrenteINE}
                uriReversoINE={uriReversoINE}
                uriPasaporte={uriPasaporte}
                uriReversoLic={uriReversoLic}
                uriFrenteLic={uriReversoLic}
                subirINE ={subirINE}
                subirPasaporte={subirPasaporte}
                subirLicencia={subirLicencia}
                isLoading={isLoading}
                />
        </>
    );
};
